<template>
    <div class="auth-wrapper auth-v2">
        <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <!-- <vuexy-logo class="w-120"/> -->
            <!-- /Brand logo-->

            <!-- Left Text-->
            <b-col
                lg="7"
                class="d-none d-lg-flex align-items-center p-5"
            >
                <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
                    <b-img
                        fluid
                        :src="imgUrl"
                        alt="Forgot password V2"
                        class="ren-side-img"
                    />
                </div>
            </b-col>
            <!-- /Left Text-->

            <!-- Forgot password-->
            <b-col
                lg="5"
                class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
                <div class="sk-picker">
                    <v-select
                        v-model="locale"
                        :options="languages"
                        label="text"
                        :clearable="false"
                    />
                </div>
                <b-col
                    sm="8"
                    md="6"
                    lg="12"
                    class="px-xl-2 mx-auto"
                >

                    <div class="text-center  mb-3">
                        <vuexy-logo class="w-50"/>
                    </div>
                    <b-card-title class="mb-1">
                        {{i18nT(`Avez-vous oublié quelque chose?`)}} 🤔
                    </b-card-title>
                    <b-card-text class="mb-2">
                        {{i18nT(`Veuillez saisir votre adresse de messagerie utilisée lors de l'inscription.`)}}
                    </b-card-text>

                    <!-- form -->
                    <validation-observer ref="simpleRules">
                        <b-form
                            class="mt-2"
                            @submit.prevent="validationForm"
                        >
                            <b-form-group
                                :label="i18nT(`Email`)"
                                label-for="forgot-password-email"
                            >
                                <validation-provider
                                    #default="{ errors }"
                                    name="Email"
                                    rules="required|email"
                                >
                                    <b-form-input
                                        id="forgot-password-email"
                                        v-model="userEmail"
                                        :state="errors.length > 0 ? false:null"
                                        name="forgot-password-email"
                                        placeholder="workemail@company.com"
                                    />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>

                            <b-button
                                type="submit"
                                variant="primary"
                                block
                            >
                                {{i18nT(`Envoyer`)}}
                            </b-button>
                        </b-form>
                    </validation-observer>

                    <p class="text-center mt-2">
                        <b-link :to="{name:'auth-login', query: {lang: locale.value} }">
                            <feather-icon icon="ChevronLeftIcon" /> {{i18nT(`Retour connexion`)}}
                        </b-link>
                    </p>
                </b-col>
            </b-col>
            <!-- /Forgot password-->
        </b-row>
    </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BImg,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import router from '@/router'
import {dictToSelectArray} from "@core/utils/utils";
import VuexyLogo from '@core/layouts/components/Logo.vue'
import useAuth from "@/auth/useAuth";

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BImg,
        BForm,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BCardTitle,
        BCardText,
        VuexyLogo,
        ValidationProvider,
        ValidationObserver,
        vSelect
    },
    created() {
        this.getLanguages()
    },
    data() {
        return {
            locale: {text: "English", value: "en"},
            languages: [],
            userEmail: '',
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            required,
            email
        }
    },
    computed: {
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        }
    },
    methods: {
        getLanguages() {
            this.$http.get(`languages`). then(({data}) => {
              this.languages = dictToSelectArray(data.data.languages)
              if(this.$route.query.lang) {
                this.locale = this.languages.find(c => c.value === this.$route.query.lang)
                console.log('locale: ', this.locale)
              }
          })
        },
        validationForm() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    const formData = new FormData()

                    formData.append('email', this.userEmail)

                    this.$http
                        .post(`forgotten`, formData)
                        .then(res => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.successMessage(res),
                                    icon: 'InfoIcon',
                                    variant: 'success'
                                }
                            })
                            router.push({ name: 'auth-login' })

                        })
                        .catch(err => {
                            this.$toast({
                                component: ToastificationContent,
                                position: 'top-right',
                                props: {
                                    title: this.errorMessage(err),
                                    icon: 'InfoIcon',
                                    variant: 'danger'
                                }
                            })
                        })
                }
            })
        }
    },
    watch: {
      locale: {
        handler: function (newVal, oldVal) {
            if(newVal && newVal.value !== 'undefined' && newVal.value !== null && newVal.value !== oldVal.value) {
                useAuth.setLocale(newVal.value)
                this.changeLang(newVal.value) 
                history.pushState({}, null, `${this.$route.path}?lang=${newVal.value}`)
            }
        },
        deep: true,
      },
  },
}
</script>

<style scoped>
.sk-picker {
    width: 150px;
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>


<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
